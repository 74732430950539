.home {
  background: #fff;
}

.header {
  padding: 18px 23px;
  background: #000000;
  display: flex;
  align-items: center;
  img {
    width: 26px;
    height: 26px;
    margin-right: 4px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    position: relative;
    .version {
      position: absolute;
      font-size: 14px;
      padding: 2px 6px;
      color: #fff;
      background: #4F60FF;
      border-radius: 2px;
      transform: scale(0.5);
      right: -22px;
      top: -4px;
      line-height: 1;
      transform-origin: right;
    }
  }
}
.nav {
  height: 321px;
  background: url('/assets/image/nav-bg.png') #000000 no-repeat;
  background-size: 100% 100%;
  position: relative;
  h2 {
    position: absolute;
    width: 253px;
    top: 53px;
    right: 98px;
    font-weight: bold;
    font-size: 34px;
    line-height: 51px;
    color: #FFFFFF;
    text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.75);
  }
  p {
    position: absolute;
    font-size: 16px;
    line-height: 30px;
    color: #C5C5C5;
    width: 308px;
    left: 24px;
    top: 169px;
  }
}
.speciality-box {
  text-align: center;
  padding: 0 16px;
  .s-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #1A1A1A;
  }
  .s-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  &.lang {
    position: relative;
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 183px;
        position: absolute;
        top: -80px;
        left: 4px;
      }
    }
    .s-title {
      margin: 26px 0 12px 0;
    }
    .s-detail {
      font-size: 11px;
      line-height: 19px;
      color: #767676;
      margin-bottom: 24px;
      z-index: 2;
    }
    .s-btn {
      padding: 0px 16px;
      width: 159px;
      height: 38px;
      color: #ffffff;
      background: #4F60FF;
      border-radius: 6px;
      font-size: 12px;
      margin-bottom: 34px;
      z-index: 2;
      position: relative;
    }
  }
  &.break {
    .s-title {
      margin-bottom: 16px;
    }
    .s-detail {
      margin-bottom: 12px;
      font-size: 11px;
      line-height: 19px;
      color: #3C3C43;
      text-align: left;
    }
    .s-btn {
      width: 71px;
      height: 28px;
      border: 1px solid #4F60FF;
      border-radius: 6px;
      color: #4F60FF;
      margin-bottom: 37px;
      font-size: 10px;
    }
  }
  &.special {
    position: relative;
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 203px;
        position: absolute;
        top: 72px;
        right: 27px;
      }
    }
    .s-title {
      margin-bottom: 27px;
    }
    ul {
      margin: 0 2px 36px 8px;
      z-index: 2;
    }
    .special-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .sp-text {
        flex: 1;
        text-align: left;
        margin-left: 18px;
        .sp-title {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #1A1A1A;
          margin-bottom: 3px;
        }
        .sp-desc {
          font-size: 11px;
          line-height: 19px;
          color: #767676;
        }
      }
    }
  }
  &.group {
    margin-bottom: 34px;
    .s-title {
      margin-bottom: 24px;
    }
    .g-item {
      border: 1px solid #E3E3E3;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 16px 20px;
      &:not(:last-child) {
        margin-bottom: 9px;
      }
      &.left {
        text-align: left;
      }
      &.right {
        text-align: right;
      }
      &.img {
        padding-bottom: 12px;
        height: 130px;
      }
      .g-title {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 7px;
      }
      .g-desc {
        font-size: 11px;
        line-height: 19px;
        color: #767676;
      }
      img {
        width: 261px;
        margin-top: 4px;
      }
    }
  }
  &.academic {
    background: url("/assets/image/academic-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 266px;
    margin-bottom: 34px;
    display: inline-block;
    .s-title {
      padding-top: 30px;
      margin-bottom: 16px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 18px;
    }
    .s-detail {
      margin-bottom: 26px;
      color: #FFFFFF;
      font-size: 11px;
      text-align: left;
      .detail-more {
        text-decoration: none;
        color: #4366E9;
      }
    }
    img {
      width: 179px;
      height: 79px;
      margin: 0 auto;
    }
  }
  &.production {
    margin-bottom: 34px;
    .s-title {
      margin-bottom: 24px;
    }
    ul {
      column-gap: 11px;
      column-count: 2;
    }
    .production-item {
      border: 1px solid #E3E3E3;
      box-sizing: border-box;
      border-radius: 15px;
      padding: 16px 8px;
      width: 166px;
      margin-bottom: 9px;
      break-inside: avoid;
      &:first-child {
        .p-title {
          width: 142px;
          line-height: 20px;
        }
      }
      .p-icon {
        margin: 0 auto 14px auto;
      }
      .p-title {
        margin: 0 auto 10px auto;
        font-size: 13px;
        line-height: 14px;
      }
      .p-desc {
        font-size: 11px;
        line-height: 19.5px;
        color: #767676;
      }
    }
  }
}
footer {
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    margin: 20px 0 14px 0;
    width: 32px;
  }
  .email {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    p {
      color: #C2C2C2;
      span{
        font-size: 15px;
      }
      a {
        font-size: 16px;
        line-height: 21px;
        text-decoration: underline;
        color: #C2C2C2;
      }
    }
  }
  .desc {
    text-align: center;
    font-size: 14px;
    line-height: 19.6px;
    padding-bottom: 20px;
    color: #C2C2C2;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    &::after {
      content: '';
      position: absolute;
      left: 33px;
      bottom: 0.5px;
      width: 324px;
      height: 1px;
      background: #323232;
    }
  }
  color: #828282;
  display: flex;
  align-items: center;
  .plc {
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    .block {
      color: #5972E8;
      font-size: 12px;
      line-height: 16.8px;
      margin-bottom: 12px;
      display: inline-block;
    }
    img {
      height: 14px;
      width: 14px;
      margin-right: 4px;
    }
    p {
      font-size: 8px;
      color: #979797;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      text-decoration: unset;
      font-size: 12px;
      line-height: 16.8px;
    }
    .register {
      margin-right: 12px;
    }
  }
}

.toast-mask {
  .adm-toast-wrap-text {
    min-width: 90%;
  }
}



